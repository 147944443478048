@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  @apply rounded mx-auto;
}

.markdown-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.markdown-img-container {
  width: 100%;
}
.markdown-img-container > div {
  position: unset !important;
}

.rewrite p pre {
  margin: 0;
  padding: 0;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 120px;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.pg-wrapper {
  width: 100%;
  height: 100%;
}

pre .cm-editor {
  background-color: transparent;
}
.cm-editor .cm-line {
  font-size: 10px;
}
